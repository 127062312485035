import React from 'react'
import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'
import Success from '../components/Success/Success'
import { Router } from "@reach/router"
import PrivateRoute from '../components/PrivateRoute/PrivateRoute'

import { PageProps } from 'gatsby'

const success: React.FC<PageProps> = ({ location }) => {
   return (
      <Layout location={location}>
         <SEO title='Sucesso' />
         <Router>
            <PrivateRoute path="/sucesso/" component={Success} />
         </Router>
      </Layout>
   )
}

export default success
